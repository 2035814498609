import { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../../utils/storage'
import { axiosInstance } from '../../../utils/axios'
import { CheckIcon, PaperClipIcon, PencilIcon, PencilSquareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/20/solid'
import ManageStockModal from '../../../components/ManageStockModal';
import AdminSidebar from '../../../components/AdminSidebar';
import Pagination from '../../../components/Pagination';

import useModalStore from '../../../stores/useModalStore';
  
  export default function ReserveStock() {
    const [devices, setDevices] = useState([]);
    const [error, setError] = useState(null); 
    // const [showModal, setShowModal] = useState(false);
    const { showModal, setShowModal } = useModalStore();
    const [activeDevice, setActiveDevice] = useState(null);

    const user = getUser();
    const navigate = useNavigate();
    

    useEffect(() => {
        fetchDevices();
    }, []);

    // useEffect(() => {
    //   if (showModal) {
    //     setTimeout(() => {
    //       alert(showModal);
    //     }, 3000);
    //   } 
    // }, [showModal]);

    const fetchDevices = async () => {
        try {
        const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT + "/api/devices");
        console.log("devices", response.data);
        setDevices(response.data.data);
        setError(null);
        } catch (error) {
        setError("Failed to fetch devices. Please try again.");
        console.error("Fetch devices error:", error);
        }
    };

    const handlePageDataUpdate = (data) => {
        setDevices(data); // Update the data for the current page
      }

    const handleDelete = async (id) => {
        try {
          await axiosInstance.delete(`${window.REACT_APP_API_ENDPOINT}/api/devices/${id}`);
          setDevices(devices.filter((device) => device.id !== id));
          setError(null);
        } catch (error) {
          setError("Failed to delete device. Please try again.");
          console.error("Delete device error:", error);
        }
      };
      if (!devices) {
        return <p>Loading...</p>;
      }
    return (
        <AdminSidebar>
       <div className="px-4 sm:px-6 lg:px-8 bg-bgBlue">
        <div className="sm:flex flex-col sm:items-start">
        
          <div className='w-full flex justify-between py-4 px-4 bg-white rounded-lg'>
              <h3 className='font-bold text-2xl'>Hello {user.name}</h3>
          </div>
        </div>
        <div className="mt-8 flow-root bg-white rounded-lg">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block lg:min-w-full max-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className='w-full flex flex-col lg:flex-row justify-between items-center py-3 px-4 bg-white rounded-lg'>
              <h4 className='font-semibold text-2xl mb-2 lg:mb-0'>Reserve Stock</h4>
              <div className='flex items-center'>
                <a href='/admin/stock/reserve' className='bg-bluePrimary text-white rounded-md px-4 py-3 font-bold mr-4'>Reserved Stocks</a>
              </div>
            </div>
            <div className='overflow-x-auto'>
                <table className="lg:min-w-full divide-y divide-gray-300">
                <thead className='bg-black'>
                  <tr>
                    <th scope="col" className="py-3.5 pl-8 pr-3 text-left text-sm font-semibold text-white">
                      Brand
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Model
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Serial
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Counter Black
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                        Counter Color
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                        Total Counter
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Date Registered
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Options
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {devices.map((device) => (
                    <tr key={device.id}>
                      <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center pl-4">
                        
                          <div className="">
                            <div className="font-medium text-gray-900">{device.brand}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                        <div className="mt-1 text-black">{device.model}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                        {device.serial_no}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{device.counter_black}</td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{device.counter_color}</td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{device.total_counter}</td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{new Date(device.created_at).toLocaleString()}</td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                            {device.device_options.blue === 1? 'Blue | ':''}
                            {device.device_options.cabinet === 1? 'Cabinet | ':''}
                            {device.device_options.finisher === 1? 'Finisher | ':''}
                            {device.device_options.notice_required === 1? 'Notice_required | ':''}
                            {device.device_options['rt-lct'] === 1? 'rt-lct | ':''}
                            {device.device_options['no-df'] === 1? 'no-df | ':''}
                        </td>
                      <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center pl-4">
                        <button onClick={()=>{setShowModal(true); setActiveDevice(device);}} class="bg-bluePrimary hover:bg-bluePrimary text-white font-bold py-2 px-4 border border-bluePrimary rounded">
                           Reserve Stock
                        </button>
                        </div>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
              <Pagination apiEndpoint={window.REACT_APP_API_ENDPOINT + "/api/devices"} onDataUpdate={handlePageDataUpdate} />
            </div>
          </div>
        </div>
        {showModal && <ManageStockModal device={activeDevice} id={activeDevice.id} status={'reserved'}  quantity={activeDevice.device_options.quantity}/>}
      </div>
      </AdminSidebar>
    )
  }
  