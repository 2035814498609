import React from 'react'
import Footer from './Layout/Footer'

export default function Home() {
  return (
  <div className='h-screen flex flex-col items-center justify-center bg-bgBlue'>
    <div className='text-center mb-20'>
        <h1 className='text-black font-bold text-3xl mb-4'>Ritex App</h1>
        <h5 className='text-textGray font-bold text-2xl mb-4'>Simplify your print and logistics jopurney with RitexApp</h5>
        <div>
            <a href='/register' class="bg-transparent hover:bg-bluePrimary text-bluePrimary font-semibold hover:text-white py-2 px-4 border border-bluePrimary hover:border-transparent rounded mr-4">
                Sign Up
            </a>
            <a href='/login' class="bg-bluePrimary hover:bg-bluePrimary text-white font-bold py-2 px-4 border border-bluePrimary rounded">
                Log In
            </a>
        </div>
    </div>
    <Footer/>
  </div>
  )
}
