import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../../utils/storage'
import { axiosInstance } from '../../../utils/axios'
import { CheckIcon, PaperClipIcon, PencilIcon, PencilSquareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/20/solid'
import ManageStockModal from '../../../components/ManageStockModal';
import AdminSidebar from '../../../components/AdminSidebar';
import Pagination from '../../../components/Pagination';
import { CSVLink } from 'react-csv';

  export default function StockList() {
    const [devices, setDevices] = useState([]);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [activeDevice, setActiveDevice] = useState(null);

    const user = getUser();
    const navigate = useNavigate();
    

    useEffect(() => {
        fetchDevices();
    }, []);

    const fetchDevices = async () => {
        try {
        const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT+"/api/devices/status/reserved");
        console.log("devices", response.data);
        setDevices(response.data.data);
        setError(null);
        } catch (error) {
        setError("Failed to fetch devices. Please try again.");
        console.error("Fetch devices error:", error);
        }
    };

    const handlePageDataUpdate = (data) => {
      setDevices(data); // Update the data for the current page
    }

    const handleDelete = async (id) => {
        try {
          await axiosInstance.delete(`${window.REACT_APP_API_ENDPOINT}/api/devices/${id}`);
          setDevices(devices.filter((device) => device.id !== id));
          setError(null);
        } catch (error) {
          setError("Failed to delete device. Please try again.");
          console.error("Delete device error:", error);
        }
      };
      const exportCSVData = async() => {
        // Transform your devices data into the format required by CSVLink
        
        try {
          const xlsxData = devices.map((device) => ([
            device.device.device_brand.name,
            device.device.device_model.name,
            device.user.name[0] + ' ' + ((device.user.last_name !== null) ? device.user.last_name[0] : ''),
            device.device.quantity,
            device.device.device_statuses[0].total_quantity,
            parseInt(device.device.quantity - device.device.device_statuses[0].total_quantity, 10)
            // Add other fields as needed
          ]));
        
          // Define the headers for your CSV file
          const headers = [
            'Brand',
            'Model',
            'User',
            'Stock',
            'Reserved Stock',
            'Total Stock'
          ];

          try {
            const response = await axiosInstance.post(
                `${window.REACT_APP_API_ENDPOINT}/api/device_export_pdf`,
                { 
                  header: headers,
                  data: xlsxData,
                  type: 'stock_list'
                
                }
            );
            
            const url = response.data
  
            // Create a link element and trigger a download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'datatable_export_' + new Date().toISOString().split('T')[0] + '.xlsx'); // Set the filename
            // document.body.appendChild(link);
            link.click();
  
            // Cleanup
            link.parentNode.removeChild(link);
            
        } catch (error) {
            console.error('Error:', error); // Handle any errors
        }
      
        } catch (error) {
          console.error('Failed to export CSV data:', error);
        }
      };

      if (!devices) {
        return <p>Loading...</p>;
      }
    return (
      <AdminSidebar>
     <div className="px-4 sm:px-6 lg:px-8 bg-bgBlue">
        <div className="sm:flex flex-col sm:items-start">
        
          <div className='w-full flex justify-between py-4 px-4 bg-white rounded-lg'>
              <h3 className='font-bold text-2xl'>Hello {user.name}</h3>
          </div>
        </div>
        <div className="mt-8 flow-root bg-white rounded-lg">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block lg:min-w-full max-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className='w-full flex flex-col lg:flex-row justify-between items-center py-3 px-4 bg-white rounded-lg'>
              <h4 className='font-semibold text-2xl mb-2 lg:mb-0'>Reserved Stocks</h4>
              <div className='flex items-center'>
                <button onClick={exportCSVData} className="bg-bluePrimary text-white rounded-md px-4 py-3 font-bold mr-0 lg:mr-4 mb-2 lg:mb-0">
                  Export Sold Stock Data as CSV
                </button>
                <a href='/admin/stock/reserve' className='border border-bluePrimary text-bluePrimary rounded-md px-4 py-3 font-bold'>Reserve a Stock +</a>
              </div>
            </div>
            <div className='overflow-x-auto'>
                <table className="lg:min-w-full divide-y divide-gray-300">
                <thead className='bg-black'>
                  <tr>
                    <th scope="col" className="py-3.5 pl-8 pr-3 text-left text-sm font-semibold text-white">
                      Brand
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Model
                    </th>
                  
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      User
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Stock
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Reserved Stock
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Total Stock
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {devices.map((deviceStatus) => (
                    <tr key={deviceStatus.id}>
                      <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center pl-4">
                        
                          <div className="">
                            <div className="font-medium text-gray-900">{deviceStatus.device.device_brand.name}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                        <div className="mt-1 text-black">{deviceStatus.device.device_model.name}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{deviceStatus.user.name[0]}. {(deviceStatus.user.last_name) && deviceStatus.user.last_name[0]}</td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                          {deviceStatus.device.quantity}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                          {deviceStatus.device.device_statuses[0].total_quantity}
                      </td>
                      <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                            {/* {deviceStatus.device.device_statuses[0].total_quantity} */}
                            {parseInt(deviceStatus.device.quantity - deviceStatus.device.device_statuses[0].total_quantity, 10)}
                        </td>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
              <Pagination apiEndpoint={window.REACT_APP_API_ENDPOINT + "/api/devices/status/reserved"} onDataUpdate={handlePageDataUpdate} />
            </div>
          </div>
        </div>
        {/* {showModal && <ManageStockModal device={activeDevice} status={'sold'} quantity={activeDevice.quantity}/>} */}
      </div>
      </AdminSidebar>
    )
  }
  