export const getUser = () => {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    else return null;
  };


  export const setUserSession = (token, user) => {
    localStorage.setItem("api_token", token);
    localStorage.setItem("user", JSON.stringify(user));
  };


  