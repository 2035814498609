import { ArrowDownIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';

const NumberInput = ({ value, onChange, title }) => {
    const handleIncrease = (e) => {
        e.preventDefault();
        onChange(value + 1);
    };

    const handleDecrease = (e) => {
        e.preventDefault();
        onChange(value - 1);
    };

    return (
        <div className="flex items-center border rounded-md px-2 py-1 border-textBlue bg-lightBlue mb-2">
            <span className='mr-2 text-sm font-medium text-gray dark:text-gray'>{title}</span>
            <button
                className="px-2 py-1 border rounded-l"
                onClick={handleDecrease}
            >
                <ChevronDownIcon className='w-4 h-4'/>
            </button>
            <input
                type="number"
                value={value}
                className="w-16 text-center bg-lightBlue text-sm font-medium text-gray dark:text-gray"
                onChange={(e) => onChange(Number(e.target.value))}
            />
            <button
                className="px-2 py-1 border rounded-r"
                onClick={handleIncrease}
            >
                <ChevronUpIcon className='w-4 h-4'/>
            </button>
        </div>
    );
};

// const App = () => {
//     const [quantity, setQuantity] = useState(1);

//     return (
        // <div className="flex justify-center items-center h-screen">
        //     <NumberInput value={quantity} onChange={setQuantity} />
        // </div>
//     );
// };

export default NumberInput;
