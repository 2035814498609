import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../../utils/storage'
import { axiosInstance } from '../../../utils/axios'
import { CheckIcon, PaperClipIcon, PencilIcon, PencilSquareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/20/solid'
import AdminSidebar from '../../../components/AdminSidebar';
import Pagination from '../../../components/Pagination';
import ModelForm from './ModelForm';
  
  export default function ModelList() {
    const [models, setModels] = useState([]);
    const [status, setStatus] = useState('Approved');
    const [error, setError] = useState(null);
    const [activeModel, setActiveModel] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const user = getUser();
    const navigate = useNavigate();
    

    useEffect(() => {
        fetchModels(status);
    }, [currentPage]);

    const handlePageChange = (page) => {      
      setCurrentPage(page);
  };

    const fetchModels = async () => {
        try {
        const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT+"/api/device-models");
        setModels(response.data.data);
        setError(null);
        } catch (error) {
        setError("Failed to fetch users. Please try again.");
        console.error("Fetch users error:", error);
        }
    };

 

    const handleDelete = async (id) => {
        try {
          await axiosInstance.delete(`${window.REACT_APP_API_ENDPOINT}/api/device-models/${id}`);
          setModels(models.filter((model) => model.id !== id));
          setError(null);
        } catch (error) {
          setError("Failed to delete model. Please try again.");
          console.error("Delete model error:", error);
        }
      };

      const handlePageDataUpdate = (data) => {
        setModels(data); // Update the data for the current page
      }

      if (!models) {
        return <p>Loading...</p>;
      }
    return (
      <AdminSidebar>
          <div className="px-4 sm:px-6 lg:px-8 bg-bgBlue">
        <div className="sm:flex flex-col sm:items-start">
        
          <div className='w-full flex justify-between py-4 px-4 bg-white rounded-lg'>
              <h3 className='font-bold text-2xl'>Hello {user.name}</h3>
          </div>
        </div>
        <ModelForm modelID={activeModel.id} updateData={fetchModels}/>
        <div className="mt-8 flow-root bg-white rounded-lg">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block lg:min-w-full max-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className='w-full flex flex-col lg:flex-row justify-between items-center py-3 px-4 bg-white rounded-lg'>
              <h4 className='font-semibold text-2xl mb-2 lg:mb-0'>Models</h4>
            </div>
              <div className='overflow-x-auto'>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className='bg-black'>
                  <tr>
                    <th scope="col" className="py-3.5 pl-8 pr-3 text-left text-sm font-semibold text-white">
                      Name
                    </th>
                    <th scope="col" className="py-3.5 pl-8 pr-3 text-left text-sm font-semibold text-white">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {models.map((model) => (
                    <tr key={model.id}>
                      <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center pl-8">
                        
                          <div className="">
                            <div className="font-medium text-gray-900">{model.name}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center pl-8">
                            <button onClick={()=>{setActiveModel(model)}} className="text-indigo-600 hover:text-indigo-900 mr-2">
                                <PencilSquareIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            </button>
                            <button onClick={() => handleDelete(model.id)}>
                                <TrashIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            </button>
                        </div>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
              <Pagination apiEndpoint={window.REACT_APP_API_ENDPOINT+"/api/device-models"} onDataUpdate={handlePageDataUpdate} />
            </div>
          </div>
         
        </div>
      </div>
      </AdminSidebar>
    )
  }
  