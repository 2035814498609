import create from 'zustand';

const useModalStore = create(set => ({
  showModal: false,
  setShowModal: (value) => set({ showModal: value }),

  client: null,
  setClient: (value) => set({ client: value }),

  serialNo: null,
  setSerialNo: (value) => set({ serialNo: value }),

  showLoading: false,
  setShowLoading: (value) => set({ showLoading: value }),
}));

export default useModalStore;
