import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../../utils/storage'
import { axiosInstance } from '../../../utils/axios'
import { CheckIcon, PaperClipIcon, PencilIcon, PencilSquareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/20/solid'
import AdminSidebar from '../../../components/AdminSidebar';
import Pagination from '../../../components/Pagination';
import BrandForm from './BrandForm';
  
  export default function BrandList() {
    const [brands, setBrands] = useState([]);
    const [status, setStatus] = useState('Approved');
    const [error, setError] = useState(null);
    const [activeBrand, setActiveBrand] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const user = getUser();
    const navigate = useNavigate();
    

    useEffect(() => {
        fetchBrands(status);
    }, [currentPage]);

    const handlePageChange = (page) => {      
      setCurrentPage(page);
  };

    const fetchBrands = async () => {
        try {
        const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT+"/api/device-brands");
        setBrands(response.data.data);
        setError(null);
        } catch (error) {
        setError("Failed to fetch brands. Please try again.");
        console.error("Fetch brands error:", error);
        }
    };

 

    const handleDelete = async (id) => {
        try {
          await axiosInstance.delete(`${window.REACT_APP_API_ENDPOINT}/api/device-brands/${id}`);
          setBrands(brands.filter((brand) => brand.id !== id));
          setError(null);
        } catch (error) {
          setError("Failed to delete brand. Please try again.");
          console.error("Delete brand error:", error);
        }
      };

      const handlePageDataUpdate = (data) => {
        setBrands(data); // Update the data for the current page
      }

      if (!brands) {
        return <p>Loading...</p>;
      }
    return (
      <AdminSidebar>
          <div className="px-4 sm:px-6 lg:px-8 bg-bgBlue">
        <div className="sm:flex flex-col sm:items-start">
        
          <div className='w-full flex justify-between py-4 px-4 bg-white rounded-lg'>
              <h3 className='font-bold text-2xl'>Hello {user.name}</h3>
          </div>
        </div>
        <BrandForm brandID={activeBrand.id} updateData={fetchBrands}/>
        <div className="mt-8 flow-root bg-white rounded-lg">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block lg:min-w-full max-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className='w-full flex flex-col lg:flex-row justify-between items-center py-3 px-4 bg-white rounded-lg'>
              <h4 className='font-semibold text-2xl mb-2 lg:mb-0'>Brands</h4>
            </div>
              <div className='overflow-x-auto'>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className='bg-black'>
                  <tr>
                    <th scope="col" className="py-3.5 pl-8 pr-3 text-left text-sm font-semibold text-white">
                      Name
                    </th>
                    <th scope="col" className="py-3.5 pl-8 pr-3 text-left text-sm font-semibold text-white">
                      Order
                    </th>
                    <th scope="col" className="py-3.5 pl-8 pr-3 text-left text-sm font-semibold text-white">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {brands.map((brand) => (
                    <tr key={brand.id}>
                      <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center pl-4">
                           <div className="font-medium text-gray-900">{brand.name}</div>
                          </div>
                      </td>
                      <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center pl-4">
                           <div className="font-medium text-gray-900">{brand.order}</div>
                          </div>
                      </td>
                      <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center pl-4">
                            <button onClick={()=>{setActiveBrand(brand)}} className="text-indigo-600 hover:text-indigo-900 mr-2">
                                <PencilSquareIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            </button>
                            <button onClick={() => handleDelete(brand.id)}>
                                <TrashIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            </button>
                        </div>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
              <Pagination apiEndpoint={window.REACT_APP_API_ENDPOINT+"/api/device-brands"} onDataUpdate={handlePageDataUpdate} />
            </div>
          </div>
         
        </div>
      </div>
      </AdminSidebar>
    )
  }
  