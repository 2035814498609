import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { axiosInstance } from '../utils/axios';
import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

function Pagination({ apiEndpoint, onDataUpdate, customQueryParams }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Combine the custom query parameters with the page parameter
    const queryParams = `page=${currentPage}${customQueryParams?customQueryParams: ''}`;

    // Fetch data from the API with the combined query parameters
    axiosInstance
      .get(`${apiEndpoint}?${queryParams}`)
      .then((response) => {
        setData(response.data.data); // Assuming your API response has a "data" property for the items
        onDataUpdate(response.data.data);
        setTotalPages(response.data.last_page); // Assuming your API response provides the total number of pages
      });
  }, [currentPage, apiEndpoint, customQueryParams]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Create an array of page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className='flex justify-center relative'>
      <div className="flex justify-center lg:my-4 absolute lg:relative w-full bg-white py-2 px-4 lg:p-0">
        <button
          onClick={handlePrevPage}
          className={`rounded px-3 py-2 border mr-2 border rounded ${
            currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600 cursor-pointer'
          }`}
          disabled={currentPage === 1}
        >
          <ChevronLeftIcon className='w-6'/>
        </button>
        <ul className="pagination flex">
          {pageNumbers.map((page) => (
            <li
              key={page}
              className={`page-item mx-1 flex items-center ${
                currentPage === page ? 'active' : ''
              }`}
            >
              <button
                className={`page-link rounded px-3 py-2 border ${
                  currentPage === page
                    ? 'bg-textBlue  text-white'
                    : 'bg-white text-blue-500 hover:bg-blue-200'
                }`}
                onClick={() => handlePageClick(page)}
              >
                {page}
              </button>
            </li>
          ))}
        </ul>
        <button
          onClick={handleNextPage}
          className={`rounded px-3 py-2 border ml-2 ${
            currentPage === totalPages
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-blue-500 hover:bg-blue-600 cursor-pointer'
          }`}
          disabled={currentPage === totalPages}
        >
          <ChevronRightIcon className='w-6'/>
        </button>
      </div>
    </div>
  );
}

export default Pagination;
