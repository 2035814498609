/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../../utils/storage'
import { axiosInstance } from '../../../utils/axios'
import AdminSidebar from '../../../components/AdminSidebar';

export default function ModelForm({modelID,updateData}) {
    const user = getUser();
    // let { modelID } = useParams();
    const [deviceBrands, setDeviceBrands] = useState([]);
    const [formData, setFormData] = useState({
      name: '',
      device_brand_id: ''
    });
    const [error, setError] = useState(null);

    const navigate = useNavigate();

  useEffect(() => {
    if (modelID) {
      fetchModel();
    }
  }, [modelID]);

  useEffect(() => {
    fetchDeviceBrands();
  }, []);

  const fetchModel = async () => {
    console.log("cool", modelID);
    try {
      const response = await axiosInstance.get(`${window.REACT_APP_API_ENDPOINT}/api/device-models/${modelID}`);
      console.log("cool2", response);
      setFormData({
        name: response.data.name,
        device_brand_id: response.data.device_brand_id,
      });
    } catch (error) {
      console.error("Error fetching model:", error);
    }
  };

  const fetchDeviceBrands = async () => {
    try {
      const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT + '/api/device-brands?type=all');
      console.log('brands', response.data);
      setDeviceBrands(response.data);
      setError(null);
    } catch (error) {
      setError('Failed to fetch devices. Please try again.');
      console.error('Fetch devices error:', error);
    }
  };

  const handleFormChange = (e) => {
    console.log("test")
    const { name, value } = e.target;
    console.log(name+" test "+value);
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    const isAnyFieldEmpty = Object.values(formData).some((value) => value === '');
    console.log("formmm", formData);
    if (isAnyFieldEmpty) {
      setError("Please fill in all fields.");
      return; 
    }
  
    try {
      if (modelID) {
        await axiosInstance.put(`${window.REACT_APP_API_ENDPOINT}/api/device-models/${modelID}`, formData);
      } else {
        await axiosInstance.post(window.REACT_APP_API_ENDPOINT + "/api/device-models", formData);
      }
      updateData();
      setError(null);
      // navigate("/admin/device-models");
    } catch (error) {
      setError("Failed to save model. Please try again.");
      console.error("Product save error:", error);
    }
  };

  return (
    // <AdminSidebar>
   <div className='pt-4'>
        <form className='w-full lg:w-1/2'>
        <div className='mb-4'>
            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Model Name
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
                
            <input
                  id="name"
                  name="name"
                  type="text"
                  autoComplete="name"
                  placeholder="First Name"
                  required
                  value={formData.name} onChange={handleFormChange}
                  className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
            </div>
            
        </div>
        <div className="flex flex-wrap pb-6">
                    {deviceBrands.map((brand) => (
                      <div
                        key={brand.id}
                        className={`flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 mb-2 ${
                          formData.device_brand_id === brand.id ? 'bg-blue-200' : 'bg-white'
                        }`}
                        onClick={() => {
                          setFormData((prevData) => ({
                            ...prevData,
                            device_brand_id: brand.id,
                          }));
                        }}
                      >
                        <div
                          className={`w-4 h-4 border border-textBlue rounded-full mr-2 ${
                            formData.device_brand_id === brand.id ? 'bg-blue-600' : 'bg-white'
                          }`}
                        />
                        {brand.name}
                      </div>
                    ))}
                     <a
                        href='/admin/device-brands'
                        className={`flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 mb-2 bg-white`}
                        
                      >
                        <div
                          className={`w-4 h-4 border border-textBlue rounded-full mr-2 bg-white`}
                        />
                        Other
                      </a>
                  </div>

    <button
        type="button"
        onClick={handleFormSubmit}
        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Save
      </button>
        </form>
        <p className="mt-2 text-sm text-red-600" id="email-error">
            {error}
        </p>
   </div>
  //  </AdminSidebar>
  )
}
