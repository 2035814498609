import { ExclamationCircleIcon, PhotoIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../../utils/storage';
import { axiosInstance } from '../../../utils/axios';
import AdminSidebar from '../../../components/AdminSidebar';

export default function BrandForm({ brandID, updateData }) {
  const user = getUser();

  const [formData, setFormData] = useState({
    name: '',
    order: '',
    logo: null,
  });
  const [existingLogo, setExistingLogo] = useState(''); // Store existing logo URL
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (brandID) {
      fetchBrand();
    }
  }, [brandID]);

  const fetchBrand = async () => {
    try {
      const response = await axiosInstance.get(`${window.REACT_APP_API_ENDPOINT}/api/device-brands/${brandID}`);
      setFormData({
        name: response.data.name,
        logo: null,
      });
      setExistingLogo(response.data.logo); // Set existing logo URL
    } catch (error) {
      console.error("Error fetching model:", error);
    }
  };

  const handleFormChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'logo' && files.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const isAnyFieldEmpty = Object.values(formData).some((value) => value === '');
    if (isAnyFieldEmpty) {
      setError("Please fill in all fields.");
      return;
    }

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('order', formData.order);
      if (formData.logo) {
        formDataToSend.append('logo', formData.logo, formData.logo.name);
      }

      if (brandID) {
        await axiosInstance.put(`${window.REACT_APP_API_ENDPOINT}/api/device-brands/${brandID}`, formDataToSend);
      } else {
        await axiosInstance.post(`${window.REACT_APP_API_ENDPOINT}/api/device-brands`, formDataToSend);
      }

      updateData();
      setError(null);
    } catch (error) {
      setError("Failed to save model. Please try again.");
      console.error("Product save error:", error);
    }
  };

  return (
    <div className="pt-4">
      <form className="w-full lg:w-1/2">
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
            Brand Name
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <input
              id="name"
              name="name"
              type="text"
              autoComplete="name"
              placeholder="Brand Name"
              required
              value={formData.name}
              onChange={handleFormChange}
              className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="order" className="block text-sm font-medium leading-6 text-gray-900">
            Brand Order
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <input
              id="order"
              name="order"
              type="text"
              autoComplete="order"
              placeholder="Brand Order"
              required
              value={formData.order}
              onChange={handleFormChange}
              className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="col-span-full mb-4">
          <label htmlFor="logo" className="block text-sm font-medium leading-6 text-gray-900">
            Brand Logo
          </label>
          <div className="bg-white mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
            <div className="text-center">
              {formData.logo ? (
                <img src={URL.createObjectURL(formData.logo)} alt="Brand Logo" className="mx-auto h-12 w-12 text-gray-300" />
              ) : existingLogo ? (
                <img src={window.REACT_APP_API_ENDPOINT+"/storage/"+existingLogo} alt="Existing Brand Logo" className="mx-auto h-12 w-12 text-gray-300" />
              ) : (
                <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
              )}
              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                <label
                  htmlFor="logo"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>Upload a logo</span>
                  <input
                    id="logo"
                    name="logo"
                    type="file"
                    className="sr-only"
                    onChange={handleFormChange}
                    accept="image/png, image/jpeg, image/jpg, image/gif"
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
            </div>
          </div>
        </div>

        <button
          type="submit"
          onClick={handleFormSubmit}
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </form>
      <p className="mt-2 text-sm text-red-600" id="email-error">
        {error}
      </p>
    </div>
  );
}
