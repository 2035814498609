import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from './Pages/Auth/Login';
import Register from './Pages/Auth/Register';

import { RequireAuth } from '../src/utils/RequireAuth'
import Header from './Pages/Layout/Header';
import UsersList from './Pages/Admin/User/UsersList';
import UserForm from './Pages/Admin/User/UserForm';
import DeviceList from './Pages/Admin/Devices/DeviceList';
import BlueStockList from './Pages/Admin/Devices/BlueStockList';
import Home from './Pages/Home';
import ReserveStock from './Pages/Admin/Devices/ReserveStock';
import ReservedStock from './Pages/Admin/Devices/ReservedStock';
import SoldStock from './Pages/Admin/Devices/SoldStock';
import SoldStockHistory from './Pages/Admin/Devices/SoldStockHistory';
import DeviceAutoForm from './Pages/General/DeviceAutoForm';
import DeviceForm from './Pages/General/DeviceForm';
import ApproveUsers from './Pages/Admin/User/ApproveUsers';
import RecycledStockHistory from './Pages/Admin/Devices/RecycledStockHistory';
import SendEmailLink from './Pages/Auth/SendEmailLink';
import PasswordResetForm from './Pages/Auth/PasswordResetForm';
import StockList from './Pages/Admin/Devices/StockList';
import ModelList from './Pages/Admin/Models/ModelList';
import ModelForm from './Pages/Admin/Models/ModelForm';
import BrandList from './Pages/Admin/Brands/BrandList';

import { getUser } from './utils/storage';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import Device from './Pages/General/Device';



function App() {
  const user = getUser();

  useEffect(() => {
    console.log("useer", user);
  
  }, [])
  
 
   return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />

           {!user ? <Route path="/login" element={<Login />} /> : <Route path="*" element={<Navigate to="/admin/devices" />} /> } 
           {!user ? <Route path="/register" element={<Register />} /> : <Route path="*" element={<Navigate to="/admin/devices" />} /> } 
            {/* <Route path="/products" element={<RequireAuth><ProductList /></RequireAuth>} /> */}
            {/* <Route path="*" element={<NoPage />} /> */}
            {user && user.role === 'Admin' ? <Route path="/admin/users" element={<UsersList />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user && user.role === 'Admin' ? <Route path="/admin/users/pending" element={<ApproveUsers />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user && user.role === 'Admin' ? <Route path="/admin/users/form" element={<UserForm />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user && user.role === 'Admin' ? <Route path="/admin/users/:userId/edit" element={<UserForm />} /> : <Route path="*" element={<Navigate to="/" />} /> } 

           
            {user ? <Route path="/admin/device" element={<DeviceList />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user && (user.role === 'Admin' || user.role === 'Stock Manager') ? <Route path="/admin/devices/blue-stock" element={<BlueStockList />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user && (user.role === 'Admin' || user.role === 'Stock Manager') ? <Route path="/admin/stock/reserve" element={<ReserveStock />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user && (user.role === 'Admin' || user.role === 'Stock Manager') ? <Route path="/admin/stock/reserved" element={<ReservedStock />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user && (user.role === 'Admin' || user.role === 'Stock Manager') ? <Route path="/admin/stock/sold" element={<SoldStock />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user && (user.role === 'Admin' || user.role === 'Stock Manager') ? <Route path="/admin/stock/history/sold" element={<SoldStockHistory />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user && (user.role === 'Admin' || user.role === 'Stock Manager') ? <Route path="/admin/stock/history/recycled" element={<RecycledStockHistory />} /> : <Route path="*" element={<Navigate to="/" />} /> } 

            {user && (user.role === 'Admin' || user.role === 'Stock Manager') ? <Route path="/stock/list" element={<StockList />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user? <Route path="/admin/devices" element={<DeviceList />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user? <Route path="/devices/form" element={<DeviceForm />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user? <Route path="/devices/form/:objID/edit" element={<DeviceForm />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user? <Route path="/devices/:objID/details" element={<Device />} /> : <Route path="*" element={<Navigate to="/" />} /> } 

            {user && (user.role === 'Admin' || user.role === 'Stock Manager') ? <Route path="/stock/list" element={<StockList />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user? <Route path="/admin/devices" element={<DeviceList />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user? <Route path="/devices/autoform" element={<DeviceAutoForm />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user? <Route path="/devices/autoform/:objID/edit" element={<DeviceAutoForm />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user? <Route path="/devices/:objID/details" element={<Device />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
    
            <Route path="/forgot-password" element={<SendEmailLink/>} />

            <Route path="/reset-password/:token" element={<PasswordResetForm/>} />

            {user && user.role === 'Admin' ? <Route path="/admin/device-models" element={<ModelList />} /> : <Route path="*" element={<Navigate to="/" />} /> } 
            {user && user.role === 'Admin' ? <Route path="/admin/device-brands" element={<BrandList />} /> : <Route path="*" element={<Navigate to="/" />} /> } 

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;