import React, { useState, useEffect, useRef } from 'react';
import QRCode from 'qrcode.react';
import { ArrowDownTrayIcon, CheckIcon, PrinterIcon } from '@heroicons/react/20/solid';
import { axiosInstance } from '../../utils/axios';
import html2pdf from 'html-to-pdf-js';
import html2canvas from 'html2canvas';
import {useReactToPrint} from 'react-to-print'; // Import the react-to-print library
import ReactDOMServer from 'react-dom/server';
import Ritex from '../../assets/Ritex.png';

// import { toPdf } from 'html-to-pdf-js';

function DeviceDetails({ deviceID, printOnLoad }) {
  const [deviceData, setDeviceData] = useState(null);
  const [error, setError] = useState(null);
  const componentRef = useRef(null);

  useEffect(() => {
    if (printOnLoad && deviceData) { // Check if deviceData is not null
      handlePrint();
    }
  }, [printOnLoad, deviceData]);
  
  useEffect(() => {
    fetchDevice();
  }, []);

  const fetchDevice = async () => {
    try {
      const response = await axiosInstance.get(`${window.REACT_APP_API_ENDPOINT}/api/devices/${deviceID}`);
      setDeviceData(response.data[0]);
      setError(null);
    } catch (error) {
      setError("Failed to fetch device. Please try again.");
      console.error("Fetch device error:", error);
    }
  };
  
  // function PrintWrapper({ deviceData }) {
  //   // const componentRef = useRef();
  
  //   return (
  //     <div>
  //       {/* <div ref={componentRef}> */}
  //         <DeviceDetails deviceData={deviceData} />
  //       {/* </div> */}
  //       <div className="page-break" />
  //       {/* <div> */}
  //         <DeviceDetails deviceData={deviceData} />
  //       {/* </div> */}
  //     </div>
  //   );
  // }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @page {
        size: A4;
        min-width: 100%;
        width: 100%; /* Set the width to 100% for the printed page */
      }
      body {
        min-width: 100%;
        width: 100%;
        padding: 0;
        margin: 0; /* Reset margins for the printed page */
      }
      #device-details{
        overflow: hidden;
        box-sizing: border-box;
         min-width: 100%;
        width: 100%; /* Set the width to 100% for the printed page */
      }
      .print-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: white; /* Set the background color if needed */
        /* Add any other styles you want for the print-bottom section */
      }
    `,
  });

  
  const handleDownload = async () => {
  
    var element = document.getElementById('device-details');
    var opt = {
      margin:       1,
      filename:     'device.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2, useCORS: true },
      jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    
    // New Promise-based usage:
    html2pdf().set(opt).from(element).save();
    
    // Old monolithic-style usage:
    html2pdf(element, opt);
  };
  
  

  if (!deviceData) {
    return <div>Loading...</div>;
  }

  return (
    <div className='flex flex-col items-center justify-center bg-bgBlue py-6 md:py-16 px-4 sm:px-8 lg:px-16'>
    <div className='flex flex-col md:flex-row mb-4 space-y-4 md:space-y-0 md:space-x-4'>
      <button id="device_print-btn" onClick={handlePrint} className='bg-bluePrimary text-white rounded-md px-4 py-3 font-bold flex items-center w-full md:w-auto'>
          <PrinterIcon className='w-4 mr-2' /> <span>Print</span>
      </button>
      <button onClick={handleDownload} className='border border-bluePrimary text-bluePrimary rounded-md px-4 py-3 font-bold flex items-center w-full md:w-auto'>
        <ArrowDownTrayIcon className='w-4 mr-2' /> <span>Download</span>
      </button>
      <a href="/devices/form" className="border border-bluePrimary text-bluePrimary rounded-md px-4 py-3 font-bold">
        Register Device +
      </a>
    </div>
    <div ref={componentRef} id="device-details" className='w-[620px] bg-white border-t-bluePrimary border-t-[20px] py-6 px-6 overflow-scroll lg:overflow-hidden'>
        <div className='flex items-center mb-4 mb-4 space-y-4 md:space-y-0 md:space-x-4'>
          {deviceData.brand_logo && <div className='w-1/4 md:mb-0'><img src={window.REACT_APP_API_ENDPOINT + deviceData.brand_logo} alt='brand-logo' className='w-full'/></div>}
          {!deviceData.brand_logo && <div className='!mt-0 w-1/4'><h3 className='uppercase font-bold text-4xl text-center text-left'>{deviceData.brand_name}</h3></div>}
          <div className='w-3/4'>
          <h3 className='!mt-0 uppercase font-bold text-4xl text-right'>{deviceData.model}</h3>
          </div>
        </div>
        <div className='text-center mb-8'>
          <h3 className='uppercase text-lightRed font-bold text-7xl'>{deviceData.serial_no}</h3>
        </div>
        <div className='flex justify-center mb-8'>
          <QRCode value={deviceData.serial_no} className='device-qr-code' />
          {/* <QRCode value="TestQRCodeValue" className='device-qr-code' /> */}
        </div>
        <div className='border border-black border-l-bluePrimary border-l-8 p-4'>
          <div className='mb-4'>
            {(deviceData.counter_color > 0 && deviceData.counter_color !== 0) && <p className='uppercase text-sm md:text-md mb-2 text-lightRed'>counter color: {deviceData.counter_color}</p>}
            {(deviceData.counter_black > 0 && deviceData.counter_black !== 0) && <p className='uppercase text-sm md:text-md mb-2'>counter black: {deviceData.counter_black}</p>}
            {(deviceData.counter_black > 0 && deviceData.total_counter !== 0) && <p className='uppercase text-sm md:text-md mb-2'>total counter: {deviceData.total_counter}</p>}
          </div>
          <div className='flex justify-between mb-4'>
            {deviceData.device_options && deviceData.device_options.finisher !== 0 && deviceData.device_options.finisher === 1 && <p className='uppercase text-sm md:text-md flex'>
              <span>finisher:</span> <CheckIcon className='w-4' />
            </p>}
            {deviceData.device_options && deviceData.device_options['rt-lct'] !== 0 && deviceData.device_options['rt-lct'] === 1 && <p className='uppercase text-sm md:text-md flex'>
              <span>rt/lct:</span> <CheckIcon className='w-4' />
            </p>}
            {deviceData.device_options && deviceData.device_options.cabinet !== 0 && deviceData.device_options.cabinet === 1 && <p className='uppercase text-sm md:text-md flex'>
              <span>cabinet:</span> <CheckIcon className='w-4' />
            </p>}
            {deviceData.device_options && deviceData.device_options['no-df'] !== 0 && deviceData.device_options['no-df'] === 1 && <p className='uppercase text-sm md:text-md flex'>
              <span>no df:</span> <CheckIcon className='w-4' />
            </p>}
          </div>
          <div className='flex justify-between mb-4'>
            <p className='uppercase text-sm md:text-md flex'>trays: {deviceData.device_options && deviceData.device_options.trays}</p>
          </div>
          <div className='flex justify-between mb-4'>
            {deviceData.device_options && deviceData.device_options.notice_required > 0 && deviceData.device_options.notice_required === 1 && <p className='uppercase text-sm md:text-md flex'>
              <span>notice: {deviceData.device_options.notice_text}</span>
            </p>}
            {deviceData.device_options && deviceData.device_options.spare_parts > 0 && <p className='uppercase text-sm md:text-md flex'>spare parts: {deviceData.device_options.spare_parts}</p>}
            {deviceData.quantity > 0 && <p className='uppercase text-sm md:text-md text-lightRed'>Quantity: {deviceData.quantity}</p>}
            {deviceData.palette > 0 && <p className='uppercase text-sm md:text-md text-lightRed'>Palette: {deviceData.palette}</p>}
          </div>         
          <div className='flex justify-between'>
            <p className='uppercase text-sm md:text-md'>REGISTER BY: {deviceData.user && deviceData.user.name[0] + '.' + (deviceData.user.last_name && deviceData.user.last_name[0])}</p>
          </div>
        </div>

        <div className='flex print-bottom justify-center items-center pt-8'>
        <img src={Ritex} alt="Company Logo" width="100px" height="100px" className="mr-10 object-cover" />
          <a href='https://www.aritex.ch/'>www.aritex.ch</a>
        </div>
      </div>
    </div>
  );
}

export default DeviceDetails;
