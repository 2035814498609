// versi "react-qr-reader" 1.0.0. component API harus disesuaikan dengan yg baru

import "../assets/qreader.css";
import { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { Scanner } from '@yudiel/react-qr-scanner';
import { axiosInstance } from '../utils/axios';

function QReader(props) {
  const [selected, setSelected] = useState("environment");
  const [startScan, setStartScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");
  const [open, setOpen] = useState(true);
  const [serialNo, setSerialNo] = useState(null);
  const [statusId, setStatusId] = useState(null);


  useEffect(() => {
    if(props.deviceStatus) {
        setStatusId(props.deviceStatus);
    }
    if(props.serial) {
        setSerialNo(props.serial);
    }
  }, [props.deviceStatus, props.serial]);

  const handleScan = async (scanData) => {
    setLoadingScan(true);
    console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "") {
      console.log(`loaded >>>`, scanData);
      setData(scanData);
      setStartScan(false);
      setLoadingScan(false);
      // setPrecScan(scanData);
    }
  };
  const handleResult = async (text, result) => {
    if(result == serialNo) {
        try {
            const response = await axiosInstance.post(window.REACT_APP_API_ENDPOINT+"/api/devices/status/confirm", {status_id: statusId});
            
            if(response.data) {
                alert(response.data);
            }

            return;            
        } catch (error) {
            console.error("ERROR handle result:", error);
        }
        
    } else {
        alert("This device wasn't confirmed for sale because it does not correspond to the scanned QR code.");
    }
  }
  const handleError = (err) => {
    console.error(err);
  };

  const handleClose = () => {
    // Perform any necessary cleanup or actions when the dialog is closed
    console.log('Dialog closed');
    props.onClose(); // Call the onClose function provided by the parent component
  };
  return (
    <div class="qreaderr">
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto flex justify-center">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 w-full lg:w-1/3">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <Dialog.Panel
                  className={`dialogCustomQr relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6`}
                   
                >
                     <Scanner
                        onResult={(text, result) => handleResult(text, result)}
                        onError={(error) => console.log(error?.message)}
                    />
                    </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </div>
  );
};
export default QReader;