import { useState, useEffect, useRef, Fragment } from 'react';
import Tesseract from 'tesseract.js';
import { Dialog, Transition } from '@headlessui/react';


function Ocr({ onSerialNumber, onCounterColor, onCounterBlack, onClose, onImageCapture, brand, onDeviceImages }) {
  const [serialNumber, setSerialNumber] = useState('');
  const [counterColor, setCounterColor] = useState('');
  const [counterBlack, setCounterBlack] = useState('');
  const [extractedText, setExtractedText] = useState('');
  const videoRef = useRef(null);
  const [open, setOpen] = useState(true);
  const [imagePath, setImagePath] = useState('');
  const [facingMode, setFacingMode] = useState('environment'); // 'user' for front camera, 'environment' for back camera

  const [capturedImage, setCapturedImage] = useState(null);
  const [deviceImages, setDeviceImages] = useState([]);

  useEffect(() => {
    console.log("propss", brand)
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const constraints = {
        video: {
          facingMode: facingMode,
          width: { ideal: 1920 }, // Set your preferred width
          height: { ideal: 1080 }, // Set your preferred height
        },
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          videoRef.current.srcObject = stream;
          videoRef.current.addEventListener('loadedmetadata', () => {
            videoRef.current.play();
          });
        })
        .catch((error) => {
          console.error('Error accessing camera:', error);
        });
    }
  }, [facingMode]);

  const toggleCamera = () => {
    // Toggle between front and back camera
    setFacingMode(facingMode === 'user' ? 'environment' : 'user');
  };

  const handleCaptureImage = () => {
    // Check if the video is playing before capturing
    if (!videoRef.current.paused) {
      // Capture a frame from the camera
      const video = videoRef.current;
      // const canvas = canvasRef.current;
      // const context = canvas.getContext('2d');
        
      // canvas.width = video.videoWidth;
      // canvas.height = video.videoHeight;

      // context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // // Convert the canvas to a data URL and set it as the captured image
      // const dataUrl = canvas.toDataURL('image/png');
      // setCapturedImage(dataUrl);
      // onImageCapture(capturedImage);
      const img = document.querySelector('.captured-image'); // Select the img element

      img.width = video.videoWidth;
      img.height = video.videoHeight;

      const imgContainer = document.querySelector('.captured-image-container');

      imgContainer.style.display = 'block';
      img.style.height = video.videoHeight;

      
  
      // Capture the current frame from the video and set it as the src attribute of the img element
      img.src = captureFrame(video);
  
      // Set the captured image data URL in state
      setCapturedImage(img.src);
      onImageCapture(img.src);

      // Perform OCR on the captured frame
      Tesseract.recognize(img,'deu')
        .catch((err) => {
          console.error('OCR Error:', err);
        })
        .then((result) => {
          let extractedText = result.data.text;
          console.log('Extracted text from camera:', extractedText);
        
          setExtractedText(extractedText)
          console.log(brand);
          if(brand.includes('icoh') || brand.includes('ashuatec')){
            console.log("coool");
            const serialNumberRegex = /Serial\s*No\.[:]*\s*([A-Za-z0-9]+)/;
            const match = extractedText.match(serialNumberRegex);

            const copierColorRegex = /Color\s*Total\s*(\d+)/i;
            const colorMatch = extractedText.match(copierColorRegex);
        
            const copierBWRegex = /B\s*&\s*W\s*Total\s*(\d+)/i;
            const colorBWMatch = extractedText.match(copierBWRegex);
  
            if (match && match[1]) {
              const serialNumberText = match[1];
              console.log('Serial Number:', serialNumberText);
              alert(serialNumberText);
              // Update the text only if the serial number is found
              setSerialNumber(serialNumberText);
              onSerialNumber(serialNumberText);
            } else {
              console.log('Serial Number not found or not recognizable.');
              // setSerialNumber('Serial Number not found or not recognizable.');
            }
  
            if (colorMatch && colorMatch[1]) {
              const counterColorText = colorMatch[1];
              console.log('Counter Color:', counterColorText);
  
              // Update the text only if the serial number is found
              setCounterColor(counterColorText);
              onCounterColor(counterColorText)
            } else {
              console.log('Counter Color not found or not recognizable.');
              // setCounterColor('Counter Color not found or not recognizable.');
            }
  
            if (colorBWMatch && colorBWMatch[1]) {
              const counterBlackText = colorBWMatch[1];
              console.log('Counter Black:', counterBlackText);
  
              // Update the text only if the serial number is found
              setCounterBlack(counterBlackText);
              onCounterBlack(counterBlackText)
            } else {
              console.log('Counter Black not found or not recognizable.');
              // setCounterBlack('Counter Black not found or not recognizable.');
            }

          }
          else if(brand.includes('onica')){
            console.log("coool", extractedText);
            const serialNumberRegex = /Serial\s*No\.?\s*['’]*\s*([A-Za-z0-9]+)/;
            const match = extractedText.match(serialNumberRegex);

            const copierColorRegex = /(\d{4,}).*?(\d{4,})(?![\d\s!])/g;
            const colorMatch = extractedText.match(copierColorRegex);
        
            const copierBWRegex = /(\d+)(?![\d\s!])(?=(?:[^\d]*\d){2}([^\d]*$))/g;
            const colorBWMatch = extractedText.match(copierBWRegex);

            // Split the extracted text into words
            const words = extractedText.split(/\s+/);

            // Filter out numbers with more than 3 digits from the words
            const filteredNumbers = words
              .filter(word => /^\d+$/.test(word) && word.length > 3);

            // Get the one before the last number among the filtered numbers
            if (filteredNumbers.length >= 2) {
              const secondToLastFilteredNumber = filteredNumbers[filteredNumbers.length - 2];
              const lastFilteredNumber = filteredNumbers[filteredNumbers.length - 1];
              
              setCounterColor(lastFilteredNumber)
              onCounterColor(lastFilteredNumber)
              
              setCounterBlack(secondToLastFilteredNumber)
              onCounterBlack(secondToLastFilteredNumber)


              console.log("Color:", secondToLastFilteredNumber);
            } else {
              console.log("No second-to-last filtered number found.");
            }
            if (match && match[1]) {
              const serialNumberText = match[1];
              console.log('Serial Number:', serialNumberText);
  
              // Update the text only if the serial number is found
              setSerialNumber(serialNumberText);
              onSerialNumber(serialNumberText);
            } else {
              console.log('Serial Number not found or not recognizable.');
              setSerialNumber('Serial Number not found or not recognizable.');
            }
          }
          else{
            setSerialNumber('Text ' + extractedText);
          }
       
        });
    }
  };

  function captureFrame(video) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Convert the canvas to a data URL and return it
    return canvas.toDataURL('image/png');
  }

  const stopCamera = () => {
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  const handleRetake = () => {
    console.log("retkee")
    setExtractedText('');
    setCapturedImage(null);
    const img = document.querySelector('.captured-image');
    const imgContainer = document.querySelector('.captured-image-container');
    imgContainer.style.display = 'none';
  };

  const handleDone = () => {
    setSerialNumber(document.getElementById('ocr_serial-no').value);
    onSerialNumber(document.getElementById('ocr_serial-no').value);

    setCounterColor(document.getElementById('ocr_counter-color').value);
    onCounterColor(document.getElementById('ocr_counter-color').value);
    
    setCounterBlack(document.getElementById('ocr_counter-black').value);
    onCounterBlack(document.getElementById('ocr_counter-black').value);
    
    stopCamera(); // Stop the camera stream
    onClose(); // Close the modal
  };

  const base64ToFile = (base64, fileName) => {
    try {
      const byteCharacters = atob(base64.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });

      // Create a File object
      const file = new File([blob], fileName, { type: 'image/jpeg' });

      return file;
    } catch (error) {
      console.error('Error decoding base64 image:', error);
      return null; // Return null or handle the error as needed
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto flex justify-center">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 w-full lg:w-1/3">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <Dialog.Panel
                  className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 top-[250px]`}
                >
                <div className="ocr">
                  <main className="ocr-main">
                    <h3>Camera OCR</h3>
                    <video ref={videoRef} className="ocr-video" playsInline muted />
                    <div className='flex justify-between'>
                      {/* <button
                        onClick={handleCaptureImage}
                        className="bg-bluePrimary text-white rounded-md px-4 py-3 font-bold lg:mr-4 mb-2 lg:mb-0 mt-2"
                      >
                        Capture
                      </button> */}
                      <button
                        onClick={toggleCamera}
                        className="bg-bluePrimary text-white rounded-md px-4 py-3 font-bold lg:mr-4 mb-2 lg:mb-0 mt-2"
                      >
                        Toggle Camera
                      </button>
                    </div>
                    <div
                      className="captured-image-container"
                      contentEditable={true}
                      style={{
                        border: '1px solid #ccc',
                        padding: '8px',
                        minHeight: '100px', 
                        overflow: 'auto',
                        visibility: capturedImage ? 'visible' : 'hidden',
                      }}
                    >
                       <img src={capturedImage} alt="Captured" className="captured-image" />
                    </div>
                    <div className="text-box font-medium mb-4">
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">Serial Number</label>
                        <div className="mt-2">
                          <input value={serialNumber} onChange={(e)=>{setSerialNumber(e.target.value);onSerialNumber(e.target.value)}} type="text" name="ocr_serial-no" id="ocr_serial-no" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                      </div>
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">Counter Color</label>
                        <div className="mt-2">
                          <input value={counterColor} onChange={(e)=>{setCounterColor(e.target.value);onCounterColor(e.target.value)}} type="text" name="ocr_counter-color" id="ocr_counter-color" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                      </div>
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">Counter Black</label>
                        <div className="mt-2">
                          <input value={counterBlack} onChange={(e)=>{setCounterBlack(e.target.value);onCounterBlack(e.target.value)}} type="text" name="ocr_counter-black" id="ocr_counter-black" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                      </div>
                    </div>

                    <button
                        onClick={handleRetake}
                      className="border border-bluePrimary text-bluePrimary rounded-md px-4 py-3 font-bold mr-2"
                    >
                      Retake
                    </button>
                    <button
                        onClick={() => {
                          handleDone()
                        }}
                      className="border border-bluePrimary text-bluePrimary rounded-md px-4 py-3 font-bold"
                    >
                      Done
                    </button>
                    
                  </main>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Ocr;

