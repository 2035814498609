import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../../utils/storage'
import { axiosInstance } from '../../../utils/axios'
import { CheckIcon, MagnifyingGlassIcon, PaperClipIcon, PencilIcon, PencilSquareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/20/solid'
import AdminSidebar from '../../../components/AdminSidebar';
import Pagination from '../../../components/Pagination';
import { CSVLink } from 'react-csv';
  
  export default function BlueStockList() {
    const [devices, setDevices] = useState([]);
    const [error, setError] = useState(null);

    const user = getUser();
    const navigate = useNavigate();
    
    const [searchCriteria, setSearchCriteria] = useState({
      type:'blue',
      brand: '',
      model: '',
      serial_no: '',
      date_registered: '',
    });
  
    const [showSearch, setShowSearch] = useState({
      brand: false,
      model: false,
      serial_no: false,
      date_registered: false,
    });

  const exportCSVData = async () => {
      // Transform your devices data into the format required by CSVLink
    const xlsxData = devices.map((device) => ([
      device.brand,
      device.model,
      device.serial_no,
      device.client_id != null ? device.client.name : null,
      device.counter_black,
      device.counter_color,
      device.total_counter,
      new Date(device.created_at).toLocaleString(),
      device.device_options.blue === 1 ? 'Yes' : '',
      device.device_options.cabinet === 1 ? 'Yes' : '',
      device.device_options.finisher === 1 ? 'Yes' : '',
      device.device_options.notice_required === 1 ? device.device_options.notice_text : '',
      device.device_options['rt-lct'] === 1 ? 'Yes' : '',
      device.device_options['no-df'] === 1 ? 'Yes' : '',

      // Add other fields as needed
    ]));
  
    // Define the headers for your CSV file
    const headers = [
      'Brand',
      'Model',
      'Serial',
      'Client',
      'Counter Black',
      'Counter Color',
      'Total Counter',
      'Registered Date',
      'Blue',
      'Cabinet',
      'Finisher',
      'Notice Required',
      'Rt Lct',
      'No Df'
    ];

    try {
        const response = await axiosInstance.post(
            `${window.REACT_APP_API_ENDPOINT}/api/device_export_pdf`,
            { 
              header: headers,
              data: xlsxData,
              type: 'bluestock_list'
            
            }
        );
        
        const url = response.data

        // Create a link element and trigger a download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'datatable_export_' + new Date().toISOString().split('T')[0] + '.xlsx'); // Set the filename
        // document.body.appendChild(link);
        link.click();

        // Cleanup
        link.parentNode.removeChild(link);
        
    } catch (error) {
        console.error('Error:', error); // Handle any errors
    }
    };
    useEffect(() => {
        fetchDevices();
    }, []);

  
  const handlePageDataUpdate = (data) => {
    setDevices(data); // Update the data for the current page
  }

    const fetchDevices = async () => {
        try {
        const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT+"/api/devices?type=blue");
        console.log("devices", response.data);
        setDevices(response.data.data);
        setError(null);
        } catch (error) {
        setError("Failed to fetch devices. Please try again.");
        console.error("Fetch devices error:", error);
        }
    };

    const handleDelete = async (id) => {
        try {
          await axiosInstance.delete(`${window.REACT_APP_API_ENDPOINT}/api/devices/${id}`);
          setDevices(devices.filter((device) => device.id !== id));
          setError(null);
        } catch (error) {
          setError("Failed to delete device. Please try again.");
          console.error("Delete device error:", error);
        }
      };
      const searchDevices = async (e) => {
        setSearchCriteria({
          ...searchCriteria,
          type: 'blue',
        });

        const searchCriteriaValues = Object.values(searchCriteria);
        if(searchCriteriaValues.every(value => value === '')){
          fetchDevices()
        }
        else if (e.key === 'Enter') {
          try {
            const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT + '/api/search-devices', {
              params: searchCriteria, // Pass the search criteria as query parameters
            });
    
            console.log('devices search', response.data);
            setDevices(response.data.data.data);
            // setError(null);
          } catch (error) {
            setError('Failed to fetch devices. Please try again.');
            console.error('Fetch devices error:', error);
          }
      }
    };
    
      const toggleSearch = (optionName) => {
        setSearchCriteria({
          brand: '',
          model: '',
          serial_no: '',
          date_registered: '',
        })
        setShowSearch((prevState) => ({
          ...prevState,
          [optionName]: !prevState[optionName],
        }));
      };
    
      const handleSearchChange = (e) => {
        // Update the search criteria state when inputs change
        setSearchCriteria({
          ...searchCriteria,
          [e.target.name]: e.target.value,
        });
      };

      if (!devices) {
        return <p>Loading...</p>;
      }
    return (
      <AdminSidebar>
     <div className="px-4 sm:px-6 lg:px-8 bg-bgBlue">
        <div className="sm:flex flex-col sm:items-start">
        
          <div className='w-full flex justify-between py-4 px-4 bg-white rounded-lg'>
              <h3 className='font-bold text-2xl'>Hello {user.name}</h3>
          </div>
        </div>
        <div className="mt-8 flow-root bg-white rounded-lg">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block lg:min-w-full max-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className='w-full flex justify-between items-center py-3 px-4 bg-white rounded-lg'>
                <h4 className='font-semibold text-2xl'>Blue Stock List</h4>
                <button onClick={exportCSVData} className="bg-bluePrimary text-white rounded-md px-4 py-3 font-bold mr-0 lg:mr-4 mb-2 lg:mb-0">
                  Export Blue Stock Data as CSV
                </button>
              </div>
            <div className='overflow-x-auto'>
                <table className="lg:min-w-full divide-y divide-gray-300">
                <thead className='bg-black'>
                  <tr>
                    <th scope="col" className="py-3.5 pl-8 pr-3 text-left text-sm font-semibold">
                    <span className='text-white flex'>Brand<MagnifyingGlassIcon className='w-4' onClick={() => toggleSearch('brand')}/></span>
                        {showSearch.brand && <input
                            type="text"
                            name="model"
                            value={searchCriteria.brand}
                            onChange={handleSearchChange}
                            onKeyDown={searchDevices}
                            placeholder="Brand"
                            className="rounded-md px-2 py-1 text-sm mr-2"
                          />}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                    <span className='text-white flex'>Model<MagnifyingGlassIcon className='w-4' onClick={() => toggleSearch('model')}/></span>
                        {showSearch.model && <input
                            type="text"
                            name="model"
                            value={searchCriteria.model}
                            onChange={handleSearchChange}
                            onKeyDown={searchDevices}
                            placeholder="Model"
                            className="rounded-md px-2 py-1 text-sm mr-2"
                          />}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                    <span className='text-white flex'>Serial<MagnifyingGlassIcon className='w-4' onClick={() => toggleSearch('serial_no')}/></span>
                        {showSearch.serial_no && <input
                            type="text"
                            name="serial_no"
                            value={searchCriteria.serial_no}
                            onChange={handleSearchChange}
                            onKeyDown={searchDevices}
                            placeholder="Serial"
                            className="rounded-md px-2 py-1 text-sm mr-2"
                          />}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Client
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Counter Black
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                        Counter Color
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Total Counter
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Date Registered
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Options
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-whites">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {devices.map((device) => (
                    <tr key={device.id}>
                      <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center pl-4">
                        
                          <div className="">
                            <div className="font-medium text-gray-900">{device.brand}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                        <div className="mt-1 text-black">{device.model}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                        {device.serial_no}
                      </td>

                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                        {device.client_id != null ? device.client.name : '----'}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{device.counter_black}</td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{device.counter_color}</td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{device.total_counter}</td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{new Date(device.created_at).toLocaleString()}</td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                            {device.device_options.blue === 1? 'Blue | ':''}
                            {device.device_options.cabinet === 1? 'Cabinet | ':''}
                            {device.device_options.finisher === 1? 'Finisher | ':''}
                            {device.device_options.notice_required === 1? 'Notice_required | ':''}
                            {device.device_options['rt-lct'] === 1? 'rt-lct | ':''}
                            {device.device_options['no-df'] === 1? 'no-df | ':''}
                        </td>
                      <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center pl-4">
                          <a href={"/devices/form/"+device.id+"/edit"} className="text-indigo-600 hover:text-indigo-900 mr-2">
                                <PencilSquareIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            </a>
                            <button onClick={() => handleDelete(device.id)}>
                                <TrashIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            </button>
                        </div>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            </div>
          </div>
          <Pagination apiEndpoint={window.REACT_APP_API_ENDPOINT+"/api/devices"} onDataUpdate={handlePageDataUpdate} customQueryParams="&type=blue"/>
        </div>
      </div>
      </AdminSidebar>
    )
  }
  