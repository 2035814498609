import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../Layout/Footer";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    username: "",
    password: "",
    password_confirmation: "",
    role: ""
  });
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("REACT_APP_API_ENDPOINT",window.REACT_APP_API_ENDPOINT);
  }, [])
  

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    // Check if the username, first name, and last name fields are not empty
    if (
      formData.username.trim() === "" ||
      formData.name.trim() === "" ||
      formData.last_name.trim() === ""
    ) {
      setError("Username, First Name, and Last Name are required fields");
      return; // Exit the function if any of these fields are empty
    }
  
    // Check if the password is at least 8 characters long
    if (formData.password.length < 8) {
      setError("Password must be at least 8 characters long");
      return; // Exit the function if the password is too short
    }
  
    // Check if the password and password_confirmation fields match
    if (formData.password !== formData.password_confirmation) {
      setError("Password and Confirm Password do not match");
      return; // Exit the function if the passwords don't match
    }
  
    try {
      const response = await axios.post(
        window.REACT_APP_API_ENDPOINT + "/api/register",
        formData
      );
      // You may implement storing the token in local storage or cookies
      console.log("User registered:", response.data);
      setError(null);
      navigate("/login"); // Redirect to the login page after successful registration
    } catch (error) {
      setError("Registration failed. Please try again.");
      console.error("Registration error:", error);
    }
  };
  

  return (
    <>
    {/*
      This example requires updating your template:

      ```
      <html class="h-full bg-gray-50">
      <body class="h-full">
      ```
    */}
    
    <div className="flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8 bg-bgBlue">
      

      <div className="flex lg:flex-row flex-col mt-10 sm:mx-auto sm:w-full sm:max-w-[1200px] mb-20">
        <div className="w-full lg:w-1/2 flex flex-col items-start h-auto px-6 py-12 shadow sm:rounded-lg sm:px-12 bg-bluePrimary mr-4 mb-4 lg:mb-0 h-auto justify-center">
            <h2 className="text-white text-4xl font-bold mb-10">RitexApp</h2>
            <h3 className="text-white text-5xl font-bold mb-6">Start your journey with us</h3>
            <p className="text-white text-lg">Your trusted companion for print ordering...</p>
        </div>
        <div className="w-full lg:w-1/2 bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <h1 className="font-bold text-4xl mb-4">Sign Up</h1>
          <form className="space-y-6" action="#" method="POST" onSubmit={handleFormSubmit}>
            <div className="mb-2">
                {/* <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                  First Name
                </label> */}
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="First Name"
                    required
                    value={formData.name} onChange={handleFormChange}
                    className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="mb-2">
              {/* <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
                Last Name
              </label> */}
              <div className="mt-2">
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  placeholder="Last Name"
                  autoComplete="last_name"
                  required
                  value={formData.last_name} onChange={handleFormChange}
                  className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="mb-2">
              {/* <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label> */}
               <div className="mt-2">
                <input
                  id="username"
                  name="username"
                  type="text"
                  placeholder="Username"
                  autoComplete="username"
                  required
                  value={formData.username} onChange={handleFormChange}
                  className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="mb-2">
           
            <select
              id="role"
              name="role"
              className="mt-2 pl-2 block w-full rounded-md border-0 py-3 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-500 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={handleFormChange}
            >
              <option className="text-gray-900" value="" disabled selected>Role</option>
              <option className="text-gray-900" value={"Stock Manager"}>Stock Manager</option>
              <option className="text-gray-900" value={"Technician"}>Technician</option>
            </select>
          </div>

            <div className="mb-2">
              {/* <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label> */}
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  required
                  value={formData.password} onChange={handleFormChange} 
                  className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="mb-2">
              {/* <label htmlFor="password_confirmation" className="block text-sm font-medium leading-6 text-gray-900">
               Confirm Password
              </label> */}
              <div className="mt-2">
                <input
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  placeholder="Confirm Password"
                  autoComplete="current-password"
                  required
                  value={formData.password_confirmation} onChange={handleFormChange} 
                  className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                  I agree to the terms and conditions
                </label>
              </div>

              <div className="text-sm leading-6">
                <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Forgot password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="uppercase flex w-full justify-center rounded-md bg-bluePrimary px-3 py-3 text-sm font-bold leading-6 text-white shadow-sm hover:bg-bluePrimary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                create an account
              </button>
            </div>
          </form>
          <p className="mt-10 text-center text-sm text-black">
            Already have an account?{' '}
            <a href="/login" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Log In
            </a>
          </p>
         
        </div>

       
      </div>
      <Footer/>
    </div>
  </>
  );
};

export default Register;
