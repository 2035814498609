import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminSidebar from '../../components/AdminSidebar';
import DeviceDetails from './DeviceDetails';


export default function Device() {
  
  const { objID } = useParams(); 
  return (
    <AdminSidebar>
      {<DeviceDetails deviceID={objID} />}
    </AdminSidebar>
  );
}
