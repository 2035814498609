import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../../utils/storage'
import { axiosInstance } from '../../../utils/axios'
import { CheckIcon, ClockIcon, MagnifyingGlassIcon, PaperClipIcon, PencilIcon, PencilSquareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/20/solid'
import ManageStockModal from '../../../components/ManageStockModal';
import AdminSidebar from '../../../components/AdminSidebar';
import Pagination from '../../../components/Pagination';
import { CSVLink } from 'react-csv';

  export default function SoldStockHistory() {
    const [devices, setDevices] = useState([]);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [activeDevice, setActiveDevice] = useState(null);

    const [searchCriteria, setSearchCriteria] = useState({
      brand: '',
      model: '',
      serial_no: '',
      date_registered: '',
    });
  
    const [showSearch, setShowSearch] = useState({
      brand: false,
      model: false,
      serial_no: false,
      date_registered: false,
    });

    const user = getUser();
    const navigate = useNavigate();
    

    useEffect(() => {
        fetchDevices();
    }, []);

    const fetchDevices = async () => {
        try {
        const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT+"/api/devices/history/sold");
        console.log("devices sold", response.data);
        setDevices(response.data.data);
        setError(null);
        } catch (error) {
        setError("Failed to fetch devices. Please try again.");
        console.error("Fetch devices error:", error);
        }
    };

    const handlePageDataUpdate = (data) => {
      setDevices(data); // Update the data for the current page
    }

    const handleDelete = async (id) => {
        try {
          await axiosInstance.delete(`${window.REACT_APP_API_ENDPOINT}/api/devices/${id}`);
          setDevices(devices.filter((device) => device.id !== id));
          setError(null);
        } catch (error) {
          setError("Failed to delete device. Please try again.");
          console.error("Delete device error:", error);
        }
      };

      const searchDevices = async (e) => {
        const searchCriteriaValues = Object.values(searchCriteria);
        if(searchCriteriaValues.every(value => value === '')){
          fetchDevices()
        }
        else if (e.key === 'Enter') {
          try {
            const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT + '/api/search-sold-history-devices/', {
              params: searchCriteria, // Pass the search criteria as query parameters
            });
    
            console.log('devices search', response.data);
            setDevices(response.data.data.data);
            // setError(null);
          } catch (error) {
            setError('Failed to fetch devices. Please try again.');
            console.error('Fetch devices error:', error);
          }
      }
    };
    
      const toggleSearch = (optionName) => {
        setSearchCriteria({
          brand: '',
          model: '',
          serial_no: '',
          date_registered: '',
        })
        setShowSearch((prevState) => ({
          ...prevState,
          [optionName]: !prevState[optionName],
        }));
      };
    
      const handleSearchChange = (e) => {
        // Update the search criteria state when inputs change
        setSearchCriteria({
          ...searchCriteria,
          [e.target.name]: e.target.value,
        });
      };

      const exportCSVData = async() => {
        // Transform your devices data into the format required by CSVLink
        const xlsxData = devices.map((device) => ([
          device.device.device_brand.name,
          device.device.device_model.name,
          device.user.name + ' '+device.user.last_name,
          device.client?device.client.name:'---',
          device.quantity,
          device.sold_date.toLocaleString(),
          device.confirmed?'YES' : 'NO'
          // Add other fields as needed
        ]));
      
        // Define the headers for your CSV file
        const headers = [
          'Brand',
          'Model',
          'User',
          'Client',
          'Sold',
          'Date Sold',
          'Verified'
          // Add other headers as needed
        ];
      
        try {
          const response = await axiosInstance.post(
              `${window.REACT_APP_API_ENDPOINT}/api/device_export_pdf`,
              { 
                header: headers,
                data: xlsxData,
                type: 'stock_history'
              
              }
          );
          
          const url = response.data
  
          // Create a link element and trigger a download
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'datatable_export_' + new Date().toISOString().split('T')[0] + '.xlsx'); // Set the filename
          // document.body.appendChild(link);
          link.click();
  
          // Cleanup
          link.parentNode.removeChild(link);
          
      } catch (error) {
          console.error('Error:', error); // Handle any errors
      }
      };

      if (!devices) {
        return <p>Loading...</p>;
      }
    return (
      <AdminSidebar>
     <div className="px-4 sm:px-6 lg:px-8 bg-bgBlue">
        <div className="sm:flex flex-col sm:items-start">
        
          <div className='w-full flex justify-between py-4 px-4 bg-white rounded-lg'>
              <h3 className='font-bold text-2xl'>Hello {user.name}</h3>
          </div>
        </div>
        <div className="mt-8 flow-root bg-white rounded-lg">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block lg:min-w-full max-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className='w-full flex justify-between items-center py-3 px-4 bg-white rounded-lg'>
              <h4 className='font-semibold text-2xl'>Sold History</h4>
              <button onClick={exportCSVData} className="bg-bluePrimary text-white rounded-md px-4 py-3 font-bold mr-0 lg:mr-4 mb-2 lg:mb-0">
                  Export Sold Stock Data as CSV
              </button>
            </div>
            {/* <div className='bg-bgBlue'>
                <a href="/admin/stock/history/sold" className='inline-block text-bluePrimary rounded-md px-4 py-3 font-bold'>Sold</a>
                <a href="/admin/stock/history/recycled" className='text-bluePrimary rounded-md px-4 py-3 font-bold'>Recycled</a>
            </div> */}
            <div className='overflow-x-auto'>
                <table className="lg:min-w-full divide-y divide-gray-300">
                <thead className='bg-black'>
                  <tr>
                   <th scope="col" className="py-3.5 pl-8 pr-3 text-left text-sm font-semibold">
                        <span className='text-white flex'>Brand<MagnifyingGlassIcon className='w-4' onClick={() => toggleSearch('brand')}/></span>
                        {showSearch.brand && <input
                          type="text"
                          name="brand"
                          value={searchCriteria.brand}
                          onChange={handleSearchChange}
                          onKeyDown={searchDevices}
                          placeholder="Brand"
                          className="rounded-md px-2 py-1 text-sm mr-2"
                        />}
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                        <span className='text-white flex'>Model<MagnifyingGlassIcon className='w-4' onClick={() => toggleSearch('model')}/></span>
                        {showSearch.model && <input
                            type="text"
                            name="model"
                            value={searchCriteria.model}
                            onChange={handleSearchChange}
                            onKeyDown={searchDevices}
                            placeholder="Model"
                            className="rounded-md px-2 py-1 text-sm mr-2"
                          />}
                      </th>
                  
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      User
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Client
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Verified
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Sold
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Date Sold
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {devices.map((deviceStatus) => (
                    <tr key={deviceStatus.id}>
                      <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center pl-4">
                        
                          <div className="">
                            <div className="font-medium text-gray-900">{deviceStatus.device.device_brand.name}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                        <div className="mt-1 text-black">{deviceStatus.device.device_model.name}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{deviceStatus.user.name} {deviceStatus.user.last_name}</td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{deviceStatus.client?deviceStatus.client.name:'---'}</td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{deviceStatus.confirmed?'YES' : 'NO'}</td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                          {deviceStatus.quantity}
                           
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                            {new Date(deviceStatus.sold_date).toLocaleString()}
                        </td>
                      {/* <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center pl-4">
                        <button onClick={()=>{setShowModal(true); setActiveDevice(deviceStatus)}} class="bg-bluePrimary hover:bg-bluePrimary text-white font-bold py-2 px-4 border border-bluePrimary rounded">
                            Verify
                        </button>
                        </div>
                      </td> */}

                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            </div>
          </div>
          <Pagination apiEndpoint={window.REACT_APP_API_ENDPOINT+"/api/devices/history/sold"} onDataUpdate={handlePageDataUpdate}/>
        </div>
        {/* {showModal && <ManageStockModal device={activeDevice} id={activeDevice.device_id} status={'sold'} quantity={activeDevice.quantity}/>} */}
      </div>
      </AdminSidebar>
    )
  }
  