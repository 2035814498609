/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ArrowRightOnRectangleIcon, ChevronDownIcon, ListBulletIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { getUser } from '../utils/storage';
import { useNavigate, useLocation } from "react-router-dom";
import Footer from '../Pages/Layout/Footer';

const adminNavigation = [
  { name: 'Manage Devices', href: '/admin/devices', icon: HomeIcon, current: true },
  { name: 'Manage Users', href: '/admin/users', icon: UsersIcon, current: false },
  { name: 'Manage Blue Stock', href: '/admin/devices/blue-stock', icon: FolderIcon, current: false },
  { name: 'Manage Reserved Stock', href: '/admin/stock/reserved', icon: CalendarIcon, current: false },
  { name: 'Manage Sold Stock', href: '/admin/stock/sold', icon: DocumentDuplicateIcon, current: false },
  { name: 'Manage Recycled Stock History', href: '/admin/stock/history/recycled', icon: DocumentDuplicateIcon, current: false },
  { name: 'Manage Sold Stock History', href: '/admin/stock/history/sold', icon: ChartPieIcon, current: false },
  { name: 'Manage Models', href: '/admin/device-models', icon: FolderIcon, current: false },
  { name: 'Manage Brands', href: '/admin/device-brands', icon: FolderIcon, current: false },

]

const stockManagerNavigation = [
  { name: 'Device List', href: '/admin/devices', icon: HomeIcon, current: true },
  { name: 'Stock List', href: '/stock/list', icon: ListBulletIcon, current: false },
  { name: 'Blue Stock List', href: '/admin/devices/blue-stock', icon: FolderIcon, current: false },
  { name: 'Reserved Stocks', href: '/admin/stock/reserved', icon: CalendarIcon, current: false },
  { name: 'Sold Stocks', href: '/admin/stock/sold', icon: DocumentDuplicateIcon, current: false },
  { name: 'Stock History', href: '/admin/stock/history/sold', icon: ChartPieIcon, current: false },
]

const technicianNavigation = [
  { name: 'Devices', href: '/admin/devices', icon: HomeIcon, current: true },
  { name: 'Register a Device', href: '/devices/form', icon: HomeIcon, current: false },

]

const teams = [
  { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
  { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
  { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
]
const userNavigation = [
  { name: 'Your profile', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const user = getUser();
const userRole = user?.role;

const sidebarBgColor = {
  Technician: 'bg-white',
  'Stock Manager': 'bg-white',
  Admin: 'bg-bluePrimary',
};

const textColor = {
  Technician: 'text-itemTextGray',
  'Stock Manager': 'text-itemTextGray',
  Admin: 'text-white',
};

const hoverBgColor = {
  Technician: 'hover:bg-bgBlue',
  'Stock Manager': 'hover:bg-bgBlue',
  Admin: 'hover:bg-white',
};

const activeBgColor = {
  Technician: 'bg-bgBlue',
  'Stock Manager': 'bg-bgBlue',
  Admin: 'bg-white',
};

export default function AdminSidebar(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navigate = useNavigate();

  const location = useLocation();

  let navigation = [];

  if (userRole === 'Admin') {
    navigation = adminNavigation.map(item => ({
      ...item,
      current: item.href === location.pathname // Compare with current location
    }));
  } else if (userRole === 'Technician') {
    navigation = technicianNavigation.map(item => ({
      ...item,
      current: item.href === location.pathname // Compare with current location
    }));
  } else if (userRole === 'Stock Manager') {
    navigation = stockManagerNavigation.map(item => ({
      ...item,
      current: item.href === location.pathname // Compare with current location
    }));
  }

  function handleLogOut(){
    console.log("log out");
    localStorage.removeItem('user'); 
    localStorage.removeItem('api_token'); 
    window.location.assign("/login");
  }
  
  return (
    <>
      {/*
        This Sidebar requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div className='min-h-full'>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className={`flex grow flex-col gap-y-5 overflow-y-auto ${sidebarBgColor[userRole]} px-6 pb-4 ring-1 ring-white/10`}>
                    <div className="flex h-16 shrink-0 items-center">
                      <h3 className='text-3xl font-bold text-bluePrimary'>RitexApp</h3>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? `text-bluePrimary ${activeBgColor[userRole]}`
                                      : `${textColor[userRole]} hover:text-bluePrimary ${hoverBgColor[userRole]}`,
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                       
                        <div className="mt-auto">
                          <li key={user.username} className="flex">
                            <div className="min-w-0">
                              <p className="text-sm font-semibold font-semibold leading-6 text-gray-400">{user.name}</p>
                              <p className="mt-1 truncate text-xs font-semibold leading-6 text-gray-400">{user.username}</p>
                            </div>
                          </li>
                          <li>
                            <a
                            onClick={handleLogOut}
                              className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-white hover:bg-gray-800 hover:text-white"
                            >
                              <ArrowRightOnRectangleIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                              Log Out
                            </a>
                          </li>
                        </div>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className={`flex grow flex-col gap-y-5 overflow-y-auto ${sidebarBgColor[userRole]} px-6 pb-4 pt-10`}>
            <div className="flex h-16 shrink-0 items-center">
              <h3 className='text-3xl font-bold text-bluePrimary'>RitexApp</h3>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? `text-bluePrimary ${activeBgColor[userRole]}`
                              : `${textColor[userRole]} hover:text-bluePrimary ${hoverBgColor[userRole]}`,
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              
              <div className='mt-auto'>
                <li key={user.username} className="flex">
                    <div className="min-w-0">
                      <p className="text-sm font-semibold font-semibold leading-6 text-gray-400">{user.name} {user.last_name}</p>
                      <p className="mt-1 truncate text-xs font-semibold leading-6 text-gray-400">{user.username}</p>
                    </div>
                  </li>

                  <li>
                    <a
                    onClick={handleLogOut}
                      className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                    >
                      <ArrowRightOnRectangleIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                      Log Out
                    </a>
                  </li>
              </div>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72 bg-bgBlue min-h-full min-h-screen bg-bgBlue flex-1">
        {/* <div className="lg:pl-72 bg-bgBlue min-h-full"> */}
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center justify-end gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

        
          </div>

          <main className="py-10 flex-grow flex flex-col">
            <div className="px-4 sm:px-6 lg:px-8 mb-10">{props.children}</div>
            <Footer/>
          </main>
        </div>
      </div>
    </>
  )
}
