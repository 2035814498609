/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../../utils/storage'
import { axiosInstance } from '../../../utils/axios'
import AdminSidebar from '../../../components/AdminSidebar';

export default function UserForm() {
    const user = getUser();
    let { userId } = useParams();

  const [formData, setFormData] = useState({
    name: '',
    last_name: '',
    username: '',
    password: '',
    role:''
    // user_id: user.id
  });
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      fetchUser();
    }
  }, [userId]);

  const fetchUser = async () => {
    console.log("cool");
    try {
      const response = await axiosInstance.get(`${window.REACT_APP_API_ENDPOINT}/api/users/${userId}`);
      console.log("cool2", response);
      setFormData({
        name: response.data.user.name,
        last_name: response.data.user.last_name,
        username: response.data.user.username,
        role:response.data.user.role
      });
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const handleFormChange = (e) => {
    console.log("test")
    const { name, value } = e.target;
    console.log(name+" test "+value);
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    const isAnyFieldEmpty = Object.values(formData).some((value) => value === '');
    console.log("formmm", formData);
    if (isAnyFieldEmpty) {
      setError("Please fill in all fields.");
      return; 
    }
    // if(formData.name === '' ||
    //   formData.last_name === '' ||
    //   formData.email === '' ||
    //   formData.password === '' ||
    //   formData.role === ''){
    //       setError("Please fill in all fields.");
    //       return; 
    //   }
  
    try {
      if (userId) {
        await axiosInstance.put(`${window.REACT_APP_API_ENDPOINT}/api/users/${userId}`, formData);
      } else {
        await axiosInstance.post(window.REACT_APP_API_ENDPOINT + "/api/users", formData);
      }
      setError(null);
      navigate("/admin/users");
    } catch (error) {
      setError("Failed to save user. Please try again.");
      console.error("Product save error:", error);
    }
  };

  return (
    <AdminSidebar>
   <div className='px-4 sm:px-6 lg:px-8'>
        <form className='w-full lg:w-1/2'>
        <div className='mb-4'>
            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                First Name
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
                
            <input
                  id="name"
                  name="name"
                  type="text"
                  autoComplete="name"
                  placeholder="First Name"
                  required
                  value={formData.name} onChange={handleFormChange}
                  className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
            </div>
            
        </div>
        <div className='mb-4'>
            <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
                Last Name
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
            <input
          type="text"
          name="last_name"
          id="last_name"
          className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Smith"
          value={formData.last_name} onChange={handleFormChange} 
        />
            </div>
           
        </div>
        <div className='mb-4'>
            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
            Username
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
            <input
          type="text"
          name="username"
          id="username"
          className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Username"
          value={formData.username} onChange={handleFormChange} 
        />
            </div>
           
        </div>
        <div className='mb-4'>
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
            <input
              type="password"
              name="password"
              id="password"
              className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={formData.password} onChange={handleFormChange} 
              required
            />
            </div>
           
        </div>

        <div className='mb-6'>
      <label className="text-base font-semibold text-gray-900">Role</label>
      <fieldset className="mt-4">
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
        <div key='admin' className="flex items-center">
              <input
                id='admin'
                name="role"
                type="radio"
                checked={formData.role === "Admin"}
                value="Admin"
                onChange={handleFormChange} 
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label htmlFor='admin' className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                Admin
              </label>
            </div>
            <div key='technician' className="flex items-center">
              <input
                id='technician'
                name="role"
                type="radio"
                checked={formData.role === "Technician"}
                value="Technician"
                onChange={handleFormChange} 
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label htmlFor='technician' className="ml-3 block text-sm font-medium leading-6 text-gray-900">
               Technician
              </label>
            </div>
            <div key='stock-manager' className="flex items-center">
              <input
                id='stock-manager'
                name="role"
                type="radio"
                checked={formData.role === "Stock Manager"}
                value="Stock Manager"
                onChange={handleFormChange} 
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label htmlFor='stock-manager' className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                Stock Manager
              </label>
            </div>
        </div>
      </fieldset>
    </div>
    <button
        type="button"
        onClick={handleFormSubmit}
        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Save
      </button>
        </form>
        <p className="mt-2 text-sm text-red-600" id="email-error">
            {error}
        </p>
   </div>
   </AdminSidebar>
  )
}
