/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState, useEffect } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import { axiosInstance } from '../../utils/axios';

const people = [
  { id: 1, name: 'Leslie Alexander' },
  // More users...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ComboboxSearch(props) {
  const [query, setQuery] = useState('')
  const [selectedItem, setSelectedItem] = useState(null)

  useEffect(() => {
    // console.log("GEX", props.values);
    if (props.model) {     
      const deviceModells = props.values;
      const selectedModel = deviceModells.find((model) => model.id === props.model);
  
      setSelectedItem(selectedModel); 
      handleSelectedItemChange(selectedModel); 
    }
  }, [props.model]); // Run this effect whenever values prop changes

  const filteredValues =
    query === ''
      ? props.values
      : props.values.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase())
        })

    const handleSelectedItemChange = (newSelectedItem) => {
      setSelectedItem(newSelectedItem);
      props.onSelectedItemChange(newSelectedItem); // Call the callback function from props
      // console.log("Selektuar", newSelectedItem);
    };

    const handleInputKeyDown = async (event) => {
      if (event.key === 'Enter' && query.trim() !== '') {
        try {
          // Send a POST request to add the new item to the API
          const response = await axiosInstance.post(window.REACT_APP_API_ENDPOINT+'/api/device-models', { name: query, device_brand_id: props.brand });
    
          const newItem = response.data; // Assuming the response contains the newly created item
          const updatedValues = [...props.values, newItem];
          props.onValuesChange(updatedValues); // Call the callback function to update the values
          setSelectedItem(newItem); // Set the newly created item as selecte
          props.onSelectedItemChange(newItem);
          // Clear the input field
          setQuery('');
        } catch (error) {
          console.error('Error adding item:', error);
        }
      }
    };
  return (
    <Combobox as="div" value={selectedItem} onChange={handleSelectedItemChange} className="w-full lg:w-1/2 mb-2 lg:mr-2">
      {/* <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">{props.title}</Combobox.Label> */}
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item) => item?.name}
          onKeyDown={handleInputKeyDown}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredValues.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredValues.map((item) => (
              <Combobox.Option
                key={item.id}
                value={item}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{item.name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
