import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from '../utils/axios';
import ComboboxSearchClient from '../components/form/ComboboxSearchClient';
// import { useModalContext } from '../contexts/Admin/Devices/ModalContext';
import useModalStore from '../stores/useModalStore';

export default function ManageStockModal(props) {
  const [firstOpen, setFirstOpen] = useState(true)
  const [open, setOpen] = useState(true)
  const [qunatityState, setQuantityState] = useState('all')
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    quantity: props.device.quantity,
    device_id: props.id,
    status: props.status,
    client: null
    // user_id: user.id
  });

  const [clients, setClients] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);

  const cancelButtonRef = useRef(null)

  const { showModal, setShowModal, client, setClient } = useModalStore();

  const [reserveDoneFormData, setReserveDoneFormData] = useState({
    is_reserve_done: false
  });

  useEffect(() => {
    console.log("proppps", props.device)
    if(firstOpen) {
      setFirstOpen(false);

      if(client != null) {           

        setFormData((prevData) => ({
          ...prevData,
          client: client,
        }));

        setClients(client);        
      } 
    }
    
  }, [])

  // useEffect(() => {
  //   // console.log("proppps", props.device)
  //   // alert(1);
  //   if(formData.client != null) {
  //     console.log("us", client);
  //     setClient(formData.client);
  //   } else {
  //     setClient(formData.client);
  //   }
  // }, formData.client)
  
  const handleClose = () => {
    setOpen(false);
    setShowModal(false);
  };

  const handleClientChange = (updatedValues) => {
    setClients(updatedValues[0]);
    setClient(updatedValues[0]);
    console.log("handleClientChange", updatedValues);
  };



  const handleSelectedModelChange = (selectedItem) => {
    // Update deviceFormData with the selected device model ID
    if(selectedItem != undefined) {
      setFormData((prevData) => ({
        ...prevData,
        client: selectedItem,
      }));

      setClient(selectedItem);
    }
  };

  const handleReserve = async () => {
    console.log("test1",formData);
    if(reserveDoneFormData.is_reserve_done) {
      setClient(null);
    }
    if(formData.quantity>props.device.quantity){
      setError("You have only "+props.device.quantity+". You can not reserve more than that.");
      return;
    }
    try {
        const response = await axiosInstance.post(window.REACT_APP_API_ENDPOINT+"/api/device-statuses", formData);

        console.log("Device status", response.data);
        setError(null);
        setOpen(false);
        setShowModal(false);
      } catch (error) {
        setError("device status error");
        console.error("device status error:", error);
      }
  }

  const handleSold = async () => {
    console.log("test2", formData);
    if(formData.quantity>props.device.quantity){
      setError("You have only "+props.device.quantity+" reserved. You can not sell more than that.");
      return;
    }
    try {
        const response = await axiosInstance.put(window.REACT_APP_API_ENDPOINT+"/api/device-statuses/"+props.device.id, formData);

        console.log("Device status update", response.data);
        setError(null);
        setOpen(false);
        setShowModal(false);
      } catch (error) {
        setError("device status update error");
        console.error("device status update error:", error);
      }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                 
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                      {props.status ==='reserved'? 'Reserve':'Mark as sold'}
                    </Dialog.Title>

                    <div className='mt-2'>
                        <div className="px-2">

                          <div class="flex justify-evenly items-end">
                            
                            <ComboboxSearchClient title="Model" client={client} onValuesChange={handleClientChange} values={clients} onSelectedItemChange={handleSelectedModelChange} />
                         
                            <div
                              className={`flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 mb-2 ${
                                reserveDoneFormData['recycled_date'] ? 'bg-blue-200' : 'bg-white'
                              }`}
                              onClick={() => {
                                setReserveDoneFormData((prevState) => ({
                                  ...prevState,
                                  is_reserve_done: !prevState.is_reserve_done,
                                }));
                              }}
                            >
                              <div
                                className={`w-4 h-4 border border-textBlue rounded-full mr-2 ${
                                  reserveDoneFormData['is_reserve_done'] ? 'bg-blue-600' : 'bg-white'
                                }`}
                              />
                              Done
                            </div>
                          </div>
                          
                        </div>
                    </div>
                    <div className="mt-2">
                      <div className='flex justify-around'>                        
                        <div className="flex items-center px-2 border border-gray-200 rounded dark:border-gray-700">
                            <input checked={qunatityState === 'all'} id="bordered-radio-1" type="radio" value={formData.quantity} onClick={(e)=>{
                              console.log("cliick", props.device.quantity)
                              setQuantityState("all")
                              setFormData((prevData) => ({ ...prevData, quantity: props.device.quantity }))
                            }} name="quantity-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                            <label for="bordered-radio-1" className="w-full py-4 px-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">All stock</label>
                        </div>
                        <div className="flex items-center px-2 border border-gray-200 rounded dark:border-gray-700">
                            {qunatityState === 'all' && <input checked={qunatityState === 'specific'} id="bordered-radio-2" type="radio" value="specific" onClick={(e)=>{setQuantityState("specific")}} name="quantity-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>}
                            {qunatityState === 'all' &&  <label for="bordered-radio-2" className="w-full py-4 px-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Quantity</label>}
                            {qunatityState === 'specific' && <input
                              type="text"
                              name="quantity-value"
                              id="quantity-value"
                              value={formData.quantity}
                              onChange={(e)=>{setFormData((prevData) => ({ ...prevData, quantity: e.target.value }));}}
                              className="peer block w-full border-b border-bluePrimary bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                            />}
                        </div>
                      </div>
                    </div>
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                        {error}
                    </p>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={props.status ==='reserved'?handleReserve:handleSold}
                  >
                    Reserve
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => {setOpen(false); setShowModal(false)}}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
