import React, { useState } from 'react';
import axios from 'axios';

const SendEmailLink = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/api/password/email', { email });
      setMessage(response.data.message);
      setIsSuccess(true);
    } catch (error) {
      setMessage('Unable to send reset link. Please check your email address.');
      setIsSuccess(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-bgBlue flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div className="flex flex-col lg:flex-row mt-10 sm:mx-auto sm:w-full sm:max-w-[1200px]">
      <div className="w-full lg:w-1/2 px-6 py-12 shadow sm:rounded-lg sm:px-12 bg-bluePrimary mr-4 mb-4 lg:mb-0">
          <h2 className="text-white text-4xl font-bold mb-10">RitexApp</h2>
          <h3 className="text-white text-5xl font-bold mb-6">Start your journey with us</h3>
          <p className="text-white text-lg">Your trusted companion for print ordering...</p>
      </div>
      <div className="w-full lg:w-1/2 bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <h1 className="font-bold text-4xl mb-4">Send link</h1>
        <form className="space-y-6" action="#" method="POST" onSubmit={handleFormSubmit}>
       
          

          <div className="mb-4">
       
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="Email"
                required
                value={email} onChange={handleEmailChange} 
                className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <p className="mt-2 text-sm text-red-600" id="email-error">
              {message}
          </p>
          <div>
            <button
              type="submit"
              className="upperacse flex w-full justify-center rounded-md bg-bluePrimary px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-bluePrimary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Send link
            </button>
          </div>
        </form>
      </div>

     
    </div>
  </div>
  );
};

export default SendEmailLink;
