import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../../utils/storage'
import { axiosInstance } from '../../../utils/axios'
import { CheckIcon, PaperClipIcon, PencilIcon, PencilSquareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/20/solid'
import AdminSidebar from '../../../components/AdminSidebar';
import Pagination from '../../../components/Pagination';
  
  export default function ApproveUsers() {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const user = getUser();
    const navigate = useNavigate();
    

    useEffect(() => {
        fetchUsers();
    }, [currentPage]);

    const handlePageChange = (page) => {      
      setCurrentPage(page);
  };

    const fetchUsers = async () => {
        try {
        const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT+"/api/users/?status=Pending&page=" + currentPage);
        setUsers(response.data.data);
        setError(null);
        } catch (error) {
        setError("Failed to fetch users. Please try again.");
        console.error("Fetch users error:", error);
        }
    };

    
    const searchUsers = async (event) => {
        console.log("cool", searchQuery);
        if (event.key === "Enter") {

        console.log("test");
        try {
        const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT+"/api/users/search/test?q="+searchQuery);
        setUsers(response.data);
        setError(null);
        } catch (error) {
        setError("Failed to search users. Please try again.");
        console.error("search users error:", error);
        }
    }
    };

    const handleDelete = async (id) => {
        try {
          await axiosInstance.delete(`${window.REACT_APP_API_ENDPOINT}/api/users/${id}`);
          setUsers(users.filter((user) => user.id !== id));
          setError(null);
        } catch (error) {
          setError("Failed to delete user. Please try again.");
          console.error("Delete user error:", error);
        }
      };

      const handleStatusChange = async (id, status) => {
        try {
          await axiosInstance.patch(`${window.REACT_APP_API_ENDPOINT}/api/users/${id}/status?status=`+status);
        //   setUsers(users.filter((user) => user.id !== id));
            fetchUsers();
          setError(null);
        } catch (error) {
          setError("Failed to delete user. Please try again.");
          console.error("Delete user error:", error);
        }
      };
      const handlePageDataUpdate = (data) => {
        setUsers(data); // Update the data for the current page
      }

      if (!users) {
        return <p>Loading...</p>;
      }
    return (
      <AdminSidebar>
      <div className="px-4 sm:px-6 lg:px-8 bg-bgBlue">
        <div className="sm:flex flex-col sm:items-start">
        
          <div className='w-full flex justify-between py-4 px-4 bg-white rounded-lg'>
              <h3 className='font-bold text-2xl'>Hello {user.name}</h3>
          </div>
        </div>
        <div className="mt-8 flow-root bg-white rounded-lg">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block lg:min-w-full max-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className='w-full flex flex-col lg:flex-row justify-between items-center py-3 px-4 bg-white rounded-lg'>
              <h4 className='font-semibold text-2xl mb-2 lg:mb-0'>Pending Users</h4>
              <input
                type="text"
                name="search"
                id="search"
                value={searchQuery}
                onChange={(e)=>setSearchQuery(e.target.value)}
                onKeyUp={searchUsers}
                className="mb-2 lg:mb-0 block w-70 rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              <div className='flex items-center'>
                <a href='/admin/users' className='bg-bluePrimary text-white rounded-md px-4 py-3 font-bold mr-4'>Users</a>
                <a href='/admin/users/form' className='border border-bluePrimary text-bluePrimary rounded-md px-4 py-3 font-bold'>Register User +</a>
              </div>
            </div>
            <div className='overflow-x-auto'>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className='bg-black'>
                  <tr>
                    <th scope="col" className="py-3.5 pl-8 pr-3 text-left text-sm font-semibold text-white">
                      Full Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Username
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Status
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Role
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {users.map((user) => (
                    <tr key={user.id}>
                      <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center pl-4">
                        
                          <div className="">
                            <div className="font-medium text-gray-900">{user.name} {user.last_name}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                        <div className="mt-1 text-black">{user.username}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            {user.status}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                        <div className="mt-1 text-black">{user.role}</div>
                      </td>
                     <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center pl-4">
                            <button onClick={() => handleStatusChange(user.id,'Approved')}>
                                <CheckIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            </button>
                            <button onClick={() => handleStatusChange(user.id, 'Rejected')}>
                                <XMarkIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
              <Pagination apiEndpoint={window.REACT_APP_API_ENDPOINT+"/api/users"} onDataUpdate={handlePageDataUpdate} customQueryParams="&status=Pending"/>
            </div>
          </div>
        
        </div>
      </div>
      </AdminSidebar>
    )
  }
  