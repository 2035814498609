import React from 'react'
import Protecht from '../../assets/Protecht.png';
import Ritex from '../../assets/Ritex.png';

const footerStyle = {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'white', // Set the background color of your footer
    padding: '20px 0', // Add padding for spacing
    borderTop: '1px solid #e0e0e0', // Add a top border for separation
    textAlign: 'center',
  };

const currentYear = new Date().getFullYear();

console.log(currentYear);
export default function Footer() {
  return (
    // <footer style={footerStyle}>
        <div className='flex flex-col justify-center items-center pt-20 lg:pt-0'>
            <div className='mb-4'>
              <a href='https://www.aritex.ch/'>
                <img src={Ritex} className='w-20'/>
              </a>
            </div>
            <div className='mb-4 flex'>
                <p className='mr-2 text-bluePrimary text-md'>Powered by: </p> <a href='https://protecht.ch/'><img src={Protecht} className='w-[100px]'/></a>
            </div>
            <div>
                <p className='mr-2 text-bluePrimary text-md'>@ {currentYear} RitexApp <span className='inline-block w-2 h-2 bg-bluePrimary rounded'></span> Privacy Policy</p>
            </div>
        </div>
    // </footer>
  )
}
