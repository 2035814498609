import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { setUserSession } from "../../utils/storage";
import Footer from "../Layout/Footer";

const Login = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(window.REACT_APP_API_ENDPOINT+"/api/login", formData);
      console.log("User logged in:", response.data);
      setError(null);
      
      const token = response.data.token;
      const user = response.data.user;

        setUserSession(token, user);
        window.location.assign("/admin/devices");
    } catch (error) {
      setError("Invalid username or password");
      console.error("Login error:", error);
    }
  };

  return (
    <>
    {/*
      This example requires updating your template:

      ```
      <html class="h-full bg-gray-50">
      <body class="h-full">
      ```
    */}
    
    <div className="flex min-h-screen bg-bgBlue flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="flex flex-col lg:flex-row mt-10 sm:mx-auto sm:w-full sm:max-w-[1200px] mb-20">
        <div className="w-full lg:w-1/2 px-6 py-12 shadow sm:rounded-lg sm:px-12 bg-bluePrimary mr-4 mb-4 lg:mb-0">
            <h2 className="text-white text-4xl font-bold mb-10">RitexApp</h2>
            <h3 className="text-white text-5xl font-bold mb-6">Start your journey with us</h3>
            <p className="text-white text-lg">Your trusted companion for print ordering...</p>
        </div>
        <div className="w-full lg:w-1/2 bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <h1 className="font-bold text-4xl mb-4">Log In</h1>
          <form className="space-y-6" action="#" method="POST" onSubmit={handleFormSubmit}>
            <div className="mb-4">
              {/* <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label> */}
              <div className="mt-2">
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  placeholder="Username"
                  required
                  value={formData.username} onChange={handleFormChange}
                  className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="mb-4">
         
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  placeholder="Password"
                  required
                  value={formData.password} onChange={handleFormChange} 
                  className="pl-2 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <p className="mt-2 text-sm text-red-600" id="email-error">
                {error}
            </p>
            <div className="flex items-center justify-between">
            

              <div className="text-sm leading-6">
                <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Forgot password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="upperacse flex w-full justify-center rounded-md bg-bluePrimary px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-bluePrimary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Log In
              </button>
            </div>
          </form>
          <p className="mt-10 text-left text-sm text-black">
            Don't have an account?{' '}
            <a href="/register" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Sign Up
            </a>
          </p>
         
        </div>

       
      </div>
      <Footer/>
    </div>
  </>
  );
};

export default Login;
